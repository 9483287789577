export default {
  area: '蓝天救援',
  objectName: '',
  //url
  hostUrl: 'https://yyjp.zhsq.cloud',
  apiHttpsUrl: 'https://ltjyapi.anluoyun.cn/',
  apiRequestUrl: 'https://ltjyapi.anluoyun.cn/api',
  apiUploadUrl: 'https://ltjyapi.anluoyun.cn/api/UploadFile/UploadImgOrFile',
  apiUploadFiles: 'https://ltjyapi.anluoyun.cn/api/UploadFile/UploadFiles',
  apiUploadvideo: 'https://ltjyapi.anluoyun.cn/api/UploadFile/UploadQiniuVideo',
}
